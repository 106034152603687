#menu-navigation a {
    text-decoration: none;
}

#menu-navigation button {
    color: #fff;
}

.logo-div .logo {
    width: 75px;
}