@font-face {
    font-family: NewhouseDTCond-ExtBold;
    src: url("/assets/fonts/NewhouseDTCond-ExtBold.ttf") format('truetype');
}

@font-face {
    font-family: NewhouseDT-ExtraLight;
    src: url("/assets/fonts/NewhouseDT-ExtraLight.ttf") format('truetype');
}

@font-face {
    font-family: NewhouseDT-Medium;
    src: url("/assets/fonts/NewhouseDT-Medium.ttf") format('truetype');
}

@font-face {
    font-family: NewhouseDTCond-Light;
    src: url("/assets/fonts/NewhouseDTCond-Light.ttf") format('truetype');
}

@font-face {
    font-family: NewhouseDTPro-Light;
    src: url("/assets/fonts/NewhouseDTPro-Light.ttf") format('truetype');
}

@font-face {
    font-family: NewhouseDTPro-Regular;
    src: url("/assets/fonts/NewhouseDTPro-Regular.ttf") format('truetype');
}

html {
    font-size: 14px;
}

body {
    background: #fafbfe !important;
    overflow-x: hidden;
}

.bg-dark {
    background: #282828;
}

.logo-div {
    flex-grow: 1;
    padding: 15px;
}
.logo {
    max-width: 250px;
}

form, .card {
    box-shadow: none !important;
}

.margin-0 {
    margin: 0px;
}

.margin-15 {
    margin: 15px !important;
}

.margin-25 {
    margin: 25px;
}

.margin-50 {
    margin: 50px;
}

.margin-75 {
    margin: 75px;
}

.margin-100 {
    margin: 100px;
}

.padding-0 {
    padding: 0px;
}

.padding-25 {
    padding: 25px;
}

.padding-50 {
    padding: 50px;
}

.padding-75 {
    padding: 75px;
}

.padding-100 {
    padding: 100px;
}

.border-right {
    border-right: 1px solid rgba(154,161,171,.15);
}

.swal-footer {
    text-align: center;
}

.swal-title,
.swal-text,
.swal-button {
    font-family: 'NewhouseDTPro-Light';
}