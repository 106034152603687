#presentations-page .add-fab {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    z-index: 999;
}

#presentations-page .listHeader {
    height: 100px;
    line-height: 100px;
    background: #ffffff;
    font-size: 50px;
    color: rgba(0, 0, 0, 0.54);
    font-family: "NewhouseDTPro-Light", sans-serif;
}

#presentations-page .card {
    min-width: 275px;
    margin-bottom: 15px;
    position: relative;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15) !important;
}

#presentations-page .card a {
    text-decoration: none;
}


#presentations-page .preview {
    position: absolute;
    top: 15%;
}

#presentations-page .preview span {
    color: #fff !important;
}

#presentations-page .title {
    font-size: 14px;
}

#presentations-page .block-title {
    background: #fff;
    border-bottom: 1px solid #ebedf2;
    padding: 0px 16px 6px 0px;
    text-transform: uppercase;
}

#presentations-page form {
    display: 'flex';
    flex: 'wrap';
    background: #fff;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
}
.input-field {
    width: 100% !important;
    /* margin-bottom: 5px; */
}

.setupButtons button:not(:nth-child(3)){
    margin-right: 15px;
}
