@font-face {
    font-family: NewhouseDTCond-ExtBold;
    src: url("/assets/fonts/NewhouseDTCond-ExtBold.ttf") format('truetype');
}

@font-face {
    font-family: NewhouseDT-ExtraLight;
    src: url("/assets/fonts/NewhouseDT-ExtraLight.ttf") format('truetype');
}

@font-face {
    font-family: NewhouseDT-Medium;
    src: url("/assets/fonts/NewhouseDT-Medium.ttf") format('truetype');
}

@font-face {
    font-family: NewhouseDTCond-Light;
    src: url("/assets/fonts/NewhouseDTCond-Light.ttf") format('truetype');
}

@font-face {
    font-family: NewhouseDTPro-Light;
    src: url("/assets/fonts/NewhouseDTPro-Light.ttf") format('truetype');
}

@font-face {
    font-family: NewhouseDTPro-Regular;
    src: url("/assets/fonts/NewhouseDTPro-Regular.ttf") format('truetype');
}

html {
    font-size: 14px;
}

body {
    background: #fafbfe !important;
    overflow-x: hidden;
}

.bg-dark {
    background: #282828;
}

.logo-div {
    flex-grow: 1;
    padding: 15px;
}
.logo {
    max-width: 250px;
}

form, .card {
    box-shadow: none !important;
}

.margin-0 {
    margin: 0px;
}

.margin-15 {
    margin: 15px !important;
}

.margin-25 {
    margin: 25px;
}

.margin-50 {
    margin: 50px;
}

.margin-75 {
    margin: 75px;
}

.margin-100 {
    margin: 100px;
}

.padding-0 {
    padding: 0px;
}

.padding-25 {
    padding: 25px;
}

.padding-50 {
    padding: 50px;
}

.padding-75 {
    padding: 75px;
}

.padding-100 {
    padding: 100px;
}

.border-right {
    border-right: 1px solid rgba(154,161,171,.15);
}

.swal-footer {
    text-align: center;
}

.swal-title,
.swal-text,
.swal-button {
    font-family: 'NewhouseDTPro-Light';
}
#menu-navigation a {
    text-decoration: none;
}

#menu-navigation button {
    color: #fff;
}

.logo-div .logo {
    width: 75px;
}
.todo-app {
    font-family: sans-serif;
  }
  
  /** add todo **/
  .add-todo {
    margin-left: 0.5rem;
  }
  
  /** todo list **/
  .todo-list {
    margin-top: 1rem;
    text-align: left;
    list-style: none;
  }
  
  /** todo item **/
  .todo-item {
    font-family: monospace;
    cursor: pointer;
    line-height: 1.5;
  }
  .todo-item__text--completed {
    text-decoration: line-through;
    color: lightgray;
  }
  
  /** visibility filters **/
  .filter {
    padding: 0.3rem 0;
    margin: 0 0.3rem;
    cursor: pointer;
  }
  .filter--active {
    border-bottom: 1px solid black;
  }
  
#assets-page {

}

#assets-page .add-fab {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    z-index: 999;
}

#assets-page .list {
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15) !important;
    width: 100%;
    position: relative;
    overflow-y: auto;
    height: 80vh;
    background: #ffffff;
    /* max-height: 300px; */
}

#assets-page .list .subheader {
    height: 100px;
    line-height: 100px;
    background: #ffffff;
    font-size: 50px;
}

#assets-page .list-section {
    background-color: inherit;
}

#assets-page .asset-details {
    background: #ffffff;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15) !important;  
    padding: 40px;
}
/* 
#assets-page .slides-field {
    height: 400px;
} */

#presentations-page .add-fab {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    z-index: 999;
}

#presentations-page .listHeader {
    height: 100px;
    line-height: 100px;
    background: #ffffff;
    font-size: 50px;
    color: rgba(0, 0, 0, 0.54);
    font-family: "NewhouseDTPro-Light", sans-serif;
}

#presentations-page .card {
    min-width: 275px;
    margin-bottom: 15px;
    position: relative;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15) !important;
}

#presentations-page .card a {
    text-decoration: none;
}


#presentations-page .preview {
    position: absolute;
    top: 15%;
}

#presentations-page .preview span {
    color: #fff !important;
}

#presentations-page .title {
    font-size: 14px;
}

#presentations-page .block-title {
    background: #fff;
    border-bottom: 1px solid #ebedf2;
    padding: 0px 16px 6px 0px;
    text-transform: uppercase;
}

#presentations-page form {
    display: 'flex';
    flex: 'wrap' 1;
    background: #fff;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
}
.input-field {
    width: 100% !important;
    /* margin-bottom: 5px; */
}

.setupButtons button:not(:nth-child(3)){
    margin-right: 15px;
}

#presentation-page {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    justify-content: center;
    padding: 0 2.5rem;
    cursor: none;
    overflow: hidden;
    background-size: cover;
}

#presentation-page .show {
    display: flex;
}

#presentation-page .hide {
    display: none;
}

#presentation-page p,
#presentation-page .custom {
    text-align: left;
    font-family: 'NewhouseDTPro-Light';
    /* font-weight: bold; */
    line-height: 1.15;
    letter-spacing: 2px;
}

#presentation-page .message {
    margin-top: 4rem;
}

#presentation-page .verse {
    margin-top: .45rem;
    padding-top: 1.5rem;
    border-left: 10px solid;
    padding-left: 2rem;
}

#presentation-page .verse p {
    margin-top: 0 !important;
    margin-bottom: .5rem;
}

#presentation-page .reference {
    /* background: rgba(255,255,255,.3); */
    display: inline;
    padding: 20px 35px 10px 40px;
    text-transform: uppercase;
    font-weight: bold;
    /* text-align: right; */
    /* background: rgba(0,0,0,.3); */
}

#presentation-page .image {
    box-shadow: 5px 10px 15px rgba(0, 0, 0, .5);
    max-height: 90vh;
    /* width: 1250px; */
}


#presentation-page .video {
    box-shadow: 5px 10px 15px rgba(0, 0, 0, .5);
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100vh;
    overflow: hidden;
    max-width: 100%;
}

#presentation-page .video iframe,
#presentation-page .video object,
#presentation-page .video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#presentation-page .logo {
    z-index: 3;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    height: 75px;
}

#presentation-page .slides-lyrics,
#presentation-page .slides-custom {
    z-index: 3;
}

#presentation-page .slides-custom {
    padding: 0 3rem;
}

.overlay {
    background: rgba(0, 0, 0, .5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

/*BLACKOUT THEME*/
#presentation-page.blackout-theme {
    background-image: radial-gradient(#000, #000);
}

#presentation-page.blackout-theme .verse {
    border-left-color: rgba(0, 0, 0, 1);
}

#presentation-page.blackout-theme .reference {
    background: rgba(0, 0, 0, 1);
}

#presentation-page.blackout-theme .reference,
#presentation-page.blackout-theme .verse,
#presentation-page.blackout-theme .custom,
#presentation-page.blackout-theme .lyrics,
#presentation-page.blackout-theme .songTitle,
#presentation-page.blackout-theme .message,
#presentation-page.blackout-theme .date,
#presentation-page.blackout-theme .title {
    color: #000000;
    text-shadow: 10px 10px 15px #000000;
}

#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

/*WHITEOUT THEME*/
#presentation-page.whiteout-theme {
    background-image: radial-gradient(#ffffff, #ffffff);
}

#presentation-page.whiteout-theme .verse {
    border-left-color: rgba(255, 255, 255, 1);
}

#presentation-page.whiteout-theme .reference {
    background: rgba(255, 255, 255, 1);
}

#presentation-page.whiteout-theme .reference,
#presentation-page.whiteout-theme .verse,
#presentation-page.whiteout-theme .custom,
#presentation-page.whiteout-theme .lyrics,
#presentation-page.whiteout-theme .songTitle,
#presentation-page.whiteout-theme .message,
#presentation-page.whiteout-theme .date,
#presentation-page.whiteout-theme .title {
    color: #ffffff;
    text-shadow: 10px 10px 15px #ffffff;
}

/*DARK THEME*/
#presentation-page.dark-theme {
    background-image: radial-gradient(#666, #000);
}

#presentation-page.dark-theme .verse {
    border-left-color: #4c4c4c;
}

#presentation-page.dark-theme .reference {
    background: #4c4c4c;
}

#presentation-page.dark-theme .reference,
#presentation-page.dark-theme .verse,
#presentation-page.dark-theme .custom,
#presentation-page.dark-theme .lyrics,
#presentation-page.dark-theme .songTitle,
#presentation-page.dark-theme .message,
#presentation-page.dark-theme .date,
#presentation-page.dark-theme .title {
    color: #ffffff;
    text-shadow: 10px 10px 15px #000000;
}

/*LIGHT THEME*/
#presentation-page.light-theme {
    background-image: radial-gradient(#fff, #666);
}

#presentation-page.light-theme .overlay {
    background: rgba(255, 255, 255, .5);
}

#presentation-page.light-theme .verse {
    border-left-color: rgba(0, 0, 0, 1);
}

#presentation-page.light-theme .reference {
    background: rgba(0, 0, 0, 1);
}

#presentation-page.light-theme .reference {
    color: #ffffff;
    font-family: 'NewhouseDTPro-Regular';
    /* text-shadow: 0px 0px 35px #282828; */
}

#presentation-page.light-theme .verse,
#presentation-page.light-theme .custom,
#presentation-page.light-theme .lyrics,
#presentation-page.light-theme .songTitle,
#presentation-page.light-theme .message,
#presentation-page.light-theme .date,
#presentation-page.light-theme .title {
    color: #000000;
    font-family: 'NewhouseDTPro-Regular';
    text-shadow: 10px 10px 15px #000000;
}

/*PURPLE-PINK THEME*/
#presentation-page.purple-pink-theme {
    background-image: radial-gradient(#ad5389, #23074d);
}

#presentation-page.purple-pink-theme .verse {
    border-left-color: #4c4c4c;
}

#presentation-page.purple-pink-theme .reference {
    background: #4c4c4c;
}

#presentation-page.purple-pink-theme .reference,
#presentation-page.purple-pink-theme .verse,
#presentation-page.purple-pink-theme .custom,
#presentation-page.purple-pink-theme .lyrics,
#presentation-page.purple-pink-theme .songTitle,
#presentation-page.purple-pink-theme .message,
#presentation-page.purple-pink-theme .date,
#presentation-page.purple-pink-theme .title {
    color: #ffffff;
    text-shadow: 10px 10px 15px #000000;
}

/*BLUE THEME*/
#presentation-page.blue-theme {
    background-image: radial-gradient(#7793BD, #213650);
}

#presentation-page.blue-theme .verse {
    border-left-color: #7793BD;
}

#presentation-page.blue-theme .reference {
    background: #7793BD;
    color: #ffffff !important;
}

#presentation-page.blue-theme .reference,
#presentation-page.blue-theme .verse,
#presentation-page.blue-theme .custom,
#presentation-page.blue-theme .lyrics,
#presentation-page.blue-theme .songTitle,
#presentation-page.blue-theme .message,
#presentation-page.blue-theme .date,
#presentation-page.blue-theme .title {
    color: #c6d4e9;
    text-shadow: 10px 10px 15px #000000;
}

/*HOFCC THEME*/
#presentation-page.hofcc-theme {
    background-image: radial-gradient(#5b7c8a, #090c0d);
}

#presentation-page.hofcc-theme .verse {
    border-left-color: #5b7c8a;
}

#presentation-page.hofcc-theme .reference {
    background: #5b7c8a;
    color: #ffffff !important;
}

#presentation-page.hofcc-theme .reference,
#presentation-page.hofcc-theme .verse,
#presentation-page.hofcc-theme .custom,
#presentation-page.hofcc-theme .lyrics,
#presentation-page.hofcc-theme .songTitle,
#presentation-page.hofcc-theme .message,
#presentation-page.hofcc-theme .date,
#presentation-page.hofcc-theme .title {
    color: #ffffff;
    text-shadow: 10px 10px 15px #090c0d;
}

/*GREEN SCREEN THEME*/
#presentation-page.green-screen-theme {
    background-color: #00b140;
}

#presentation-page.green-screen-theme .verse {
    border-left-color: #5b7c8a;
}

#presentation-page.green-screen-theme .reference {
    background: #5b7c8a;
    color: #ffffff !important;
}

#presentation-page.green-screen-theme .reference,
#presentation-page.green-screen-theme .verse,
#presentation-page.green-screen-theme .custom,
#presentation-page.green-screen-theme .lyrics,
#presentation-page.green-screen-theme .songTitle,
#presentation-page.green-screen-theme .message,
#presentation-page.green-screen-theme .date,
#presentation-page.green-screen-theme .title {
    color: #ffffff;
    text-shadow: 10px 10px 15px #090c0d;
}

/*HOFCC ANIMATION THEME*/
#presentation-page.hofcc-ani-theme {
    /* background-image: radial-gradient(#5b7c8a, #090c0d); */
    background: linear-gradient(33deg, #5b7c8a, #090c0d, #3f4243, #5b7c8a);
    background-size: 800% 800%;
    animation: AnimationName 240s ease infinite;
}

#presentation-page.hofcc-ani-theme .verse {
    border-left-color: #5b7c8a;
}

#presentation-page.hofcc-ani-theme .reference {
    background: #5b7c8a;
    color: #ffffff !important;
}

#presentation-page.hofcc-ani-theme .reference,
#presentation-page.hofcc-ani-theme .verse,
#presentation-page.hofcc-ani-theme .custom,
#presentation-page.hofcc-ani-theme .lyrics,
#presentation-page.hofcc-ani-theme .songTitle,
#presentation-page.hofcc-ani-theme .message,
#presentation-page.hofcc-ani-theme .date,
#presentation-page.hofcc-ani-theme .title {
    color: #ffffff;
    text-shadow: 10px 10px 15px #090c0d;
}

.animated {
    animation-duration: 2s;
}

/* #presentation-page .custom {
    position: relative;
} */

#presentation-page .custom  {
    position: relative;
}

#presentation-page .custom span {
    display: none;
}

#presentation-page .custom h1+span{
    line-height: .8;
    font-family: 'NewhouseDTCond-ExtBold';
    font-size: 15rem;
    opacity: .1;
    position: fixed;
    bottom: -40px;
    left: 0;
    text-align: center;
    display: block;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scaleY(1.2);
}

#presentation-page .custom h1 {
    font-family: 'NewhouseDTCond-ExtBold';
    letter-spacing: 10px;
    /* opacity: .1; */
    line-height: 1;
    z-index: 3;
    position: relative;
}

#presentation-page .custom h2 {
    font-family: 'NewhouseDTCond-Light';
    letter-spacing: 3px;
}
@keyframes AnimationName { 
    0%{background-position:0% 72%}
    50%{background-position:100% 29%}
    100%{background-position:0% 72%}
}
.block-title {
    background: #fff;
    border-bottom: 1px solid #ebedf2;
    padding: 16px 16px 6px 16px;
    text-transform: uppercase;
}

#controller-page {
    /* background-image: radial-gradient(#7793BD, #2d4963); */
}

#controller-page .logo-div {
    /* text-align: center; */
    padding: 20px 25px 5px 25px;
}

#controller-page .logo-div img {
    width: 100px;
}

#controller-page .header-title-div {
    text-align: right;
    padding: 25px 25px 10px 25px;
}

#controller-page .slides-div {
    box-shadow: 0 0 35px 0 rgba(209, 215, 223, 0.15);
}

#controller-page .slides-div header {
    background: #282828;
}

#controller-page .slides-list {
    width: 100%;
    background-color: #ffffff;
    overflow: auto;
    height: 80vh;
    max-height: 80vh;
}

#controller-page .slides-list .slides-list-icon {
    color: #000000;
}

#controller-page .slides-list .actions {
    border-bottom: 1px solid rgba(154,161,171,.15);
}

#controller-page .slides-list .actions .button-div {
    text-align: right;
}

#controller-page .slides-list .hide {
    visibility: hidden;
}

#controller-page .slides-list .show {
    visibility: visible;
}

#controller-page .slides-list .item {
    border-bottom: 1px solid #dbdbdb;
    position: relative;
}

#controller-page .slides-list .item.active,
#controller-page #history-list .item.active {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

#controller-page .slides-list .item.itemTitle {
    background: #f3f3f3;
}

#controller-page .slides-list .item button { 
    /* position: absolute;
    right: 0px; */
}

#controller-page .slides-list .item p {
    font-family: 'NewhouseDTPro-Light';
}

#controller-page .settings-div {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0 0 35px 0 rgba(209, 215, 223, 0.15);
    margin-bottom: 1rem;
}

#controller-page .settings-div .section-title {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(154,161,171,.15);
}

#controller-page .settings-div .toggle-display-div,
#controller-page .settings-div .color-picker-div,
#controller-page .settings-div .theme-select-div,
#controller-page .settings-div .font-size-div, 
#controller-page .settings-div .animation-select-div,
#controller-page .settings-div .background-image-div,
#controller-page .settings-div .text-alignment-div  { 
    text-align: center;
}

#controller-page .settings-div .font-size-div .font-size-input-div {
    background: #ffffff;
}

#controller-page .settings-div #font-size {
    z-index: 99;
    width: 150px;
}

#controller-page .settings-div #theme-select .input-autocomplete,
#controller-page .settings-div #text-alignment-select .input-autocomplete,
#controller-page .settings-div .animation-select-div #animation-select .input-autocomplete {
    z-index: 99;
    width: 150px;
}

#controller-page .settings-div .color-picker-div #color-picker {
    z-index: 99;
}

#controller-page .settings-div #background-image {
    z-index: 99;
}

#controller-page .settings-div .background-image-div .background-image-input-div {
    background: #ffffff;
    width: 150px;
}

#controller-page .song-search-div {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
    margin-top: .5rem;
    margin-left: 0px;
    width: 100%;
}

#controller-page .song-search-div .header-div {
    position: relative;
}

#controller-page .song-search-div .header-div button {
    position: absolute;
    right: 25px;
    top: 0;
}

#controller-page .song-search-div .section-title {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(154,161,171,.15);
}

#controller-page .button-insert button {
    border-left: 1px solid rgba(154,161,171,.15);
}

#controller-page .button-insert button {
    padding: .65rem 1rem;
    line-height: 1.5;
    width: 90%;
}

#controller-page .verse-search-div {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
    margin-bottom: 1rem;
}

#controller-page #songs-select .input-autocomplete {
    z-index: 4;
}

#controller-page .verse-search-div .input-autocomplete {
    z-index: 3;
}

#controller-page .verse-search-div .section-title {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(154,161,171,.15);
}

#controller-page .verse-search-div .input-field-verse {
    margin-top: 0;
    margin-bottom: 0;
}

#controller-page .verse-search-div .input-field-verse input {
    padding: 10.5px 14px;
}

#controller-page .button-get button {
    border-left: 1px solid rgba(154,161,171,.15);
}

#controller-page .button-get button {
    padding: .65rem 1rem;
    line-height: 1.5;
    width: 90%;
}

#controller-page .text-editor-div {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
}

#controller-page .text-editor-div .section-title {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(154,161,171,.15);
}

#controller-page .editorClassName {
    height: 200px;
    border: 1px solid #fafbfe;
    padding: 0 10px;
    background: rgba(0, 0, 0, 0.05);
}

#controller-page #tempVerseDiv {
    display: none;
}

#controller-page .buttons-div {
    background: #ffffff;
    margin-top: 10px;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
    margin-bottom: 1rem;

}

#controller-page .buttons-div div {
    padding: 8px;
    text-align: center;
    border-right: 1px solid rgba(154,161,171,.15);
}

#controller-page .buttons-div div button {
    padding: .65rem 1rem;
    line-height: 1.5;
    width: 90%;
}

#controller-page button .button-icon {
    margin-right: 10px;
}

#controller-page .input-autocomplete {
    font-family: 'NewhouseDTPro-Light';
    text-align: left;
}

#controller-page .resources-div {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
    margin-bottom: 1rem;
}

#controller-page .resources-div .section-title {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(154,161,171,.15);
}

#controller-page #history-list .verse {
    padding-right: 3rem;
}
.block-title {
    background: #fff;
    border-bottom: 1px solid #ebedf2;
    padding: 16px 16px 6px 16px;
    text-transform: uppercase;
}

#remote-page {
    /* background-image: radial-gradient(#7793BD, #2d4963); */
}

#remote-page .fabButton {
    position: absolute;
    z-index: 1;
    top: -17px;
    left: auto;
    right: 15px;
    margin: 0 auto;
    background: #5b7c8a;
}

#remote-page .appBar {
    top: auto;
    bottom: 0;
}

.appBar-verse {
    left: 0;
    right: 0;
    display: block;
    text-align: center;
}

.appBar-Tools button {
    font-size: 1rem;
    text-transform: uppercase;
}

.fabButton-verse {
    position: absolute !important;
    z-index: 1;
    top: 17px;
    left: auto;
    right: 15px;
    margin: 0 auto; 
    background: #5b7c8a !important;
}

#remote-page .logo-div {
    /* text-align: center; */
    padding: 20px 25px 5px 25px;
}

#remote-page .logo-div img {
    width: 100px;
}

#remote-page .header-title-div {
    text-align: right;
    padding: 25px 25px 10px 25px;
}


#remote-page .header-section {
    border-bottom: 10px solid black;
}

.verse-search-div-remote {
    height: 100vh;
    /* overflow: hidden; */
}

/* #remote-page .verse-controls {
    background: #5b7c8a;
    box-shadow: 0 5px 10px rgba(0,0,0,.5);
} */

.verse-controls button {
    font-size: 1rem;
    color: #ffffff;
}

.passage-select {
    margin-top: 85px;
    max-height: 85vh;
    overflow-y: auto;
}

.select-option .selected-option {
    font-weight: bold;
}

.verse-select {
    padding: 0 24px;
}

.verse-select button {
    text-transform: initial;
    text-align: left;
    align-items: baseline;
    width: 100%;
    justify-content: left;
}

.verse-select button .reference {
    font-size: 1.25rem;
    font-weight: bold;
    margin-right: 15px;
}

.verse-select .active {
    background: rgba(91,124,138,.3)!important;
}

#remote-page .worship-slides-div {
    /* height: 100vh; */
    padding-right: 0;
    overflow-y: auto;
}

#remote-page .itemTitle {
    border-top: 1px solid #5b7c8a;
    border-bottom: 1px solid #5b7c8a;
    background: rgba(91,124,138,.7);
}

#remote-page .itemTitle span p,
#remote-page .itemTitle svg {
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
}

#remote-page .item {
    /* border-bottom: 1px solid rgba(0,0,0,.5); */
}
#remote-page .item.active {
    background: rgba(91,124,138,.3)!important;
}

.songsList-divider button {
    font-size: 1rem;
    font-weight: bold;
}

.slides-list-remote {
    overflow-y: hidden;
    margin-bottom: 65px !important;
}


.books-select {
    padding: 0 24px;
}
.verse-search-div-remote .books-select {
    margin: 0 !important;
}

.books-select button span {
    justify-content: left;
    text-align: left;
}
#presentation-page {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    justify-content: center;
    padding: 0 2.5rem;
    cursor: none;
    overflow: hidden;
    background-size: cover;
}

#presentation-page .show {
    display: flex;
}

#presentation-page .hide {
    display: none;
}

#presentation-page p,
#presentation-page .custom {
    text-align: left;
    font-family: 'NewhouseDTPro-Light';
    /* font-weight: bold; */
    line-height: 1.15;
    letter-spacing: 2px;
}

#presentation-page .message {
    margin-top: 4rem;
}

#presentation-page .verse {
    margin-top: .45rem;
    padding-top: 1.5rem;
    border-left: 10px solid;
    padding-left: 2rem;
}

#presentation-page .verse p {
    margin-top: 0 !important;
    margin-bottom: .5rem;
}

#presentation-page .reference {
    /* background: rgba(255,255,255,.3); */
    display: inline;
    padding: 20px 35px 10px 40px;
    text-transform: uppercase;
    font-weight: bold;
    /* text-align: right; */
    /* background: rgba(0,0,0,.3); */
}

#presentation-page .image {
    box-shadow: 5px 10px 15px rgba(0, 0, 0, .5);
    max-height: 90vh;
    /* width: 1250px; */
}


#presentation-page .video {
    box-shadow: 5px 10px 15px rgba(0, 0, 0, .5);
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100vh;
    overflow: hidden;
    max-width: 100%;
}

#presentation-page .video iframe,
#presentation-page .video object,
#presentation-page .video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#presentation-page .logo {
    z-index: 3;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    height: 75px;
}

#presentation-page .slides-lyrics,
#presentation-page .slides-custom {
    z-index: 3;
}

#presentation-page .slides-custom {
    padding: 0 3rem;
}

.overlay {
    background: rgba(0, 0, 0, .5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

/*GREEN SCREEN THEME*/
#presentation-page.green-screen-theme {
    background-color: #00b140;
}

#presentation-page.green-screen-theme .verse {
    border-left-color: transparent;
    border: none;
    padding-left: 0;
}

#presentation-page.green-screen-theme .reference {
    background: #5b7c8a;
    color: #ffffff !important;
    /* width: 500px;
    text-align: center;
    display: block; */
}

#presentation-page.green-screen-theme .reference,
#presentation-page.green-screen-theme .verse,
#presentation-page.green-screen-theme .custom,
#presentation-page.green-screen-theme .lyrics,
#presentation-page.green-screen-theme .songTitle,
#presentation-page.green-screen-theme .message,
#presentation-page.green-screen-theme .date,
#presentation-page.green-screen-theme .title {
    color: #ffffff;
    text-shadow: 10px 10px 15px #090c0d;
}


/* @media screen and (max-width: 768px) {    
    html {
        font-size: 10px !important;
    }

    .logo {
        display: none;
    }
} */
