#presentation-page {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    justify-content: center;
    padding: 0 2.5rem;
    cursor: none;
    overflow: hidden;
    background-size: cover;
}

#presentation-page .show {
    display: flex;
}

#presentation-page .hide {
    display: none;
}

#presentation-page p,
#presentation-page .custom {
    text-align: left;
    font-family: 'NewhouseDTPro-Light';
    /* font-weight: bold; */
    line-height: 1.15;
    letter-spacing: 2px;
}

#presentation-page .message {
    margin-top: 4rem;
}

#presentation-page .verse {
    margin-top: .45rem;
    padding-top: 1.5rem;
    border-left: 10px solid;
    padding-left: 2rem;
}

#presentation-page .verse p {
    margin-top: 0 !important;
    margin-bottom: .5rem;
}

#presentation-page .reference {
    /* background: rgba(255,255,255,.3); */
    display: inline;
    padding: 20px 35px 10px 40px;
    text-transform: uppercase;
    font-weight: bold;
    /* text-align: right; */
    /* background: rgba(0,0,0,.3); */
}

#presentation-page .image {
    box-shadow: 5px 10px 15px rgba(0, 0, 0, .5);
    max-height: 90vh;
    /* width: 1250px; */
}


#presentation-page .video {
    box-shadow: 5px 10px 15px rgba(0, 0, 0, .5);
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100vh;
    overflow: hidden;
    max-width: 100%;
}

#presentation-page .video iframe,
#presentation-page .video object,
#presentation-page .video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#presentation-page .logo {
    z-index: 3;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    height: 75px;
}

#presentation-page .slides-lyrics,
#presentation-page .slides-custom {
    z-index: 3;
}

#presentation-page .slides-custom {
    padding: 0 3rem;
}

.overlay {
    background: rgba(0, 0, 0, .5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

/*GREEN SCREEN THEME*/
#presentation-page.green-screen-theme {
    background-color: #00b140;
}

#presentation-page.green-screen-theme .verse {
    border-left-color: transparent;
    border: none;
    padding-left: 0;
}

#presentation-page.green-screen-theme .reference {
    background: #5b7c8a;
    color: #ffffff !important;
    /* width: 500px;
    text-align: center;
    display: block; */
}

#presentation-page.green-screen-theme .reference,
#presentation-page.green-screen-theme .verse,
#presentation-page.green-screen-theme .custom,
#presentation-page.green-screen-theme .lyrics,
#presentation-page.green-screen-theme .songTitle,
#presentation-page.green-screen-theme .message,
#presentation-page.green-screen-theme .date,
#presentation-page.green-screen-theme .title {
    color: #ffffff;
    text-shadow: 10px 10px 15px #090c0d;
}


/* @media screen and (max-width: 768px) {    
    html {
        font-size: 10px !important;
    }

    .logo {
        display: none;
    }
} */