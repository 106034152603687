.block-title {
    background: #fff;
    border-bottom: 1px solid #ebedf2;
    padding: 16px 16px 6px 16px;
    text-transform: uppercase;
}

#controller-page {
    /* background-image: radial-gradient(#7793BD, #2d4963); */
}

#controller-page .logo-div {
    /* text-align: center; */
    padding: 20px 25px 5px 25px;
}

#controller-page .logo-div img {
    width: 100px;
}

#controller-page .header-title-div {
    text-align: right;
    padding: 25px 25px 10px 25px;
}

#controller-page .slides-div {
    box-shadow: 0 0 35px 0 rgba(209, 215, 223, 0.15);
}

#controller-page .slides-div header {
    background: #282828;
}

#controller-page .slides-list {
    width: 100%;
    background-color: #ffffff;
    overflow: auto;
    height: 80vh;
    max-height: 80vh;
}

#controller-page .slides-list .slides-list-icon {
    color: #000000;
}

#controller-page .slides-list .actions {
    border-bottom: 1px solid rgba(154,161,171,.15);
}

#controller-page .slides-list .actions .button-div {
    text-align: right;
}

#controller-page .slides-list .hide {
    visibility: hidden;
}

#controller-page .slides-list .show {
    visibility: visible;
}

#controller-page .slides-list .item {
    border-bottom: 1px solid #dbdbdb;
    position: relative;
}

#controller-page .slides-list .item.active,
#controller-page #history-list .item.active {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

#controller-page .slides-list .item.itemTitle {
    background: #f3f3f3;
}

#controller-page .slides-list .item button { 
    /* position: absolute;
    right: 0px; */
}

#controller-page .slides-list .item p {
    font-family: 'NewhouseDTPro-Light';
}

#controller-page .settings-div {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0 0 35px 0 rgba(209, 215, 223, 0.15);
    margin-bottom: 1rem;
}

#controller-page .settings-div .section-title {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(154,161,171,.15);
}

#controller-page .settings-div .toggle-display-div,
#controller-page .settings-div .color-picker-div,
#controller-page .settings-div .theme-select-div,
#controller-page .settings-div .font-size-div, 
#controller-page .settings-div .animation-select-div,
#controller-page .settings-div .background-image-div,
#controller-page .settings-div .text-alignment-div  { 
    text-align: center;
}

#controller-page .settings-div .font-size-div .font-size-input-div {
    background: #ffffff;
}

#controller-page .settings-div #font-size {
    z-index: 99;
    width: 150px;
}

#controller-page .settings-div #theme-select .input-autocomplete,
#controller-page .settings-div #text-alignment-select .input-autocomplete,
#controller-page .settings-div .animation-select-div #animation-select .input-autocomplete {
    z-index: 99;
    width: 150px;
}

#controller-page .settings-div .color-picker-div #color-picker {
    z-index: 99;
}

#controller-page .settings-div #background-image {
    z-index: 99;
}

#controller-page .settings-div .background-image-div .background-image-input-div {
    background: #ffffff;
    width: 150px;
}

#controller-page .song-search-div {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
    margin-top: .5rem;
    margin-left: 0px;
    width: 100%;
}

#controller-page .song-search-div .header-div {
    position: relative;
}

#controller-page .song-search-div .header-div button {
    position: absolute;
    right: 25px;
    top: 0;
}

#controller-page .song-search-div .section-title {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(154,161,171,.15);
}

#controller-page .button-insert button {
    border-left: 1px solid rgba(154,161,171,.15);
}

#controller-page .button-insert button {
    padding: .65rem 1rem;
    line-height: 1.5;
    width: 90%;
}

#controller-page .verse-search-div {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
    margin-bottom: 1rem;
}

#controller-page #songs-select .input-autocomplete {
    z-index: 4;
}

#controller-page .verse-search-div .input-autocomplete {
    z-index: 3;
}

#controller-page .verse-search-div .section-title {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(154,161,171,.15);
}

#controller-page .verse-search-div .input-field-verse {
    margin-top: 0;
    margin-bottom: 0;
}

#controller-page .verse-search-div .input-field-verse input {
    padding: 10.5px 14px;
}

#controller-page .button-get button {
    border-left: 1px solid rgba(154,161,171,.15);
}

#controller-page .button-get button {
    padding: .65rem 1rem;
    line-height: 1.5;
    width: 90%;
}

#controller-page .text-editor-div {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
}

#controller-page .text-editor-div .section-title {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(154,161,171,.15);
}

#controller-page .editorClassName {
    height: 200px;
    border: 1px solid #fafbfe;
    padding: 0 10px;
    background: rgba(0, 0, 0, 0.05);
}

#controller-page #tempVerseDiv {
    display: none;
}

#controller-page .buttons-div {
    background: #ffffff;
    margin-top: 10px;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
    margin-bottom: 1rem;

}

#controller-page .buttons-div div {
    padding: 8px;
    text-align: center;
    border-right: 1px solid rgba(154,161,171,.15);
}

#controller-page .buttons-div div button {
    padding: .65rem 1rem;
    line-height: 1.5;
    width: 90%;
}

#controller-page button .button-icon {
    margin-right: 10px;
}

#controller-page .input-autocomplete {
    font-family: 'NewhouseDTPro-Light';
    text-align: left;
}

#controller-page .resources-div {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
    margin-bottom: 1rem;
}

#controller-page .resources-div .section-title {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(154,161,171,.15);
}

#controller-page #history-list .verse {
    padding-right: 3rem;
}