#presentation-page {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    justify-content: center;
    padding: 0 2.5rem;
    cursor: none;
    overflow: hidden;
    background-size: cover;
}

#presentation-page .show {
    display: flex;
}

#presentation-page .hide {
    display: none;
}

#presentation-page p,
#presentation-page .custom {
    text-align: left;
    font-family: 'NewhouseDTPro-Light';
    /* font-weight: bold; */
    line-height: 1.15;
    letter-spacing: 2px;
}

#presentation-page .message {
    margin-top: 4rem;
}

#presentation-page .verse {
    margin-top: .45rem;
    padding-top: 1.5rem;
    border-left: 10px solid;
    padding-left: 2rem;
}

#presentation-page .verse p {
    margin-top: 0 !important;
    margin-bottom: .5rem;
}

#presentation-page .reference {
    /* background: rgba(255,255,255,.3); */
    display: inline;
    padding: 20px 35px 10px 40px;
    text-transform: uppercase;
    font-weight: bold;
    /* text-align: right; */
    /* background: rgba(0,0,0,.3); */
}

#presentation-page .image {
    box-shadow: 5px 10px 15px rgba(0, 0, 0, .5);
    max-height: 90vh;
    /* width: 1250px; */
}


#presentation-page .video {
    box-shadow: 5px 10px 15px rgba(0, 0, 0, .5);
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100vh;
    overflow: hidden;
    max-width: 100%;
}

#presentation-page .video iframe,
#presentation-page .video object,
#presentation-page .video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#presentation-page .logo {
    z-index: 3;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    height: 75px;
}

#presentation-page .slides-lyrics,
#presentation-page .slides-custom {
    z-index: 3;
}

#presentation-page .slides-custom {
    padding: 0 3rem;
}

.overlay {
    background: rgba(0, 0, 0, .5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

/*BLACKOUT THEME*/
#presentation-page.blackout-theme {
    background-image: radial-gradient(#000, #000);
}

#presentation-page.blackout-theme .verse {
    border-left-color: rgba(0, 0, 0, 1);
}

#presentation-page.blackout-theme .reference {
    background: rgba(0, 0, 0, 1);
}

#presentation-page.blackout-theme .reference,
#presentation-page.blackout-theme .verse,
#presentation-page.blackout-theme .custom,
#presentation-page.blackout-theme .lyrics,
#presentation-page.blackout-theme .songTitle,
#presentation-page.blackout-theme .message,
#presentation-page.blackout-theme .date,
#presentation-page.blackout-theme .title {
    color: #000000;
    text-shadow: 10px 10px 15px #000000;
}

#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

/*WHITEOUT THEME*/
#presentation-page.whiteout-theme {
    background-image: radial-gradient(#ffffff, #ffffff);
}

#presentation-page.whiteout-theme .verse {
    border-left-color: rgba(255, 255, 255, 1);
}

#presentation-page.whiteout-theme .reference {
    background: rgba(255, 255, 255, 1);
}

#presentation-page.whiteout-theme .reference,
#presentation-page.whiteout-theme .verse,
#presentation-page.whiteout-theme .custom,
#presentation-page.whiteout-theme .lyrics,
#presentation-page.whiteout-theme .songTitle,
#presentation-page.whiteout-theme .message,
#presentation-page.whiteout-theme .date,
#presentation-page.whiteout-theme .title {
    color: #ffffff;
    text-shadow: 10px 10px 15px #ffffff;
}

/*DARK THEME*/
#presentation-page.dark-theme {
    background-image: radial-gradient(#666, #000);
}

#presentation-page.dark-theme .verse {
    border-left-color: #4c4c4c;
}

#presentation-page.dark-theme .reference {
    background: #4c4c4c;
}

#presentation-page.dark-theme .reference,
#presentation-page.dark-theme .verse,
#presentation-page.dark-theme .custom,
#presentation-page.dark-theme .lyrics,
#presentation-page.dark-theme .songTitle,
#presentation-page.dark-theme .message,
#presentation-page.dark-theme .date,
#presentation-page.dark-theme .title {
    color: #ffffff;
    text-shadow: 10px 10px 15px #000000;
}

/*LIGHT THEME*/
#presentation-page.light-theme {
    background-image: radial-gradient(#fff, #666);
}

#presentation-page.light-theme .overlay {
    background: rgba(255, 255, 255, .5);
}

#presentation-page.light-theme .verse {
    border-left-color: rgba(0, 0, 0, 1);
}

#presentation-page.light-theme .reference {
    background: rgba(0, 0, 0, 1);
}

#presentation-page.light-theme .reference {
    color: #ffffff;
    font-family: 'NewhouseDTPro-Regular';
    /* text-shadow: 0px 0px 35px #282828; */
}

#presentation-page.light-theme .verse,
#presentation-page.light-theme .custom,
#presentation-page.light-theme .lyrics,
#presentation-page.light-theme .songTitle,
#presentation-page.light-theme .message,
#presentation-page.light-theme .date,
#presentation-page.light-theme .title {
    color: #000000;
    font-family: 'NewhouseDTPro-Regular';
    text-shadow: 10px 10px 15px #000000;
}

/*PURPLE-PINK THEME*/
#presentation-page.purple-pink-theme {
    background-image: radial-gradient(#ad5389, #23074d);
}

#presentation-page.purple-pink-theme .verse {
    border-left-color: #4c4c4c;
}

#presentation-page.purple-pink-theme .reference {
    background: #4c4c4c;
}

#presentation-page.purple-pink-theme .reference,
#presentation-page.purple-pink-theme .verse,
#presentation-page.purple-pink-theme .custom,
#presentation-page.purple-pink-theme .lyrics,
#presentation-page.purple-pink-theme .songTitle,
#presentation-page.purple-pink-theme .message,
#presentation-page.purple-pink-theme .date,
#presentation-page.purple-pink-theme .title {
    color: #ffffff;
    text-shadow: 10px 10px 15px #000000;
}

/*BLUE THEME*/
#presentation-page.blue-theme {
    background-image: radial-gradient(#7793BD, #213650);
}

#presentation-page.blue-theme .verse {
    border-left-color: #7793BD;
}

#presentation-page.blue-theme .reference {
    background: #7793BD;
    color: #ffffff !important;
}

#presentation-page.blue-theme .reference,
#presentation-page.blue-theme .verse,
#presentation-page.blue-theme .custom,
#presentation-page.blue-theme .lyrics,
#presentation-page.blue-theme .songTitle,
#presentation-page.blue-theme .message,
#presentation-page.blue-theme .date,
#presentation-page.blue-theme .title {
    color: #c6d4e9;
    text-shadow: 10px 10px 15px #000000;
}

/*HOFCC THEME*/
#presentation-page.hofcc-theme {
    background-image: radial-gradient(#5b7c8a, #090c0d);
}

#presentation-page.hofcc-theme .verse {
    border-left-color: #5b7c8a;
}

#presentation-page.hofcc-theme .reference {
    background: #5b7c8a;
    color: #ffffff !important;
}

#presentation-page.hofcc-theme .reference,
#presentation-page.hofcc-theme .verse,
#presentation-page.hofcc-theme .custom,
#presentation-page.hofcc-theme .lyrics,
#presentation-page.hofcc-theme .songTitle,
#presentation-page.hofcc-theme .message,
#presentation-page.hofcc-theme .date,
#presentation-page.hofcc-theme .title {
    color: #ffffff;
    text-shadow: 10px 10px 15px #090c0d;
}

/*GREEN SCREEN THEME*/
#presentation-page.green-screen-theme {
    background-color: #00b140;
}

#presentation-page.green-screen-theme .verse {
    border-left-color: #5b7c8a;
}

#presentation-page.green-screen-theme .reference {
    background: #5b7c8a;
    color: #ffffff !important;
}

#presentation-page.green-screen-theme .reference,
#presentation-page.green-screen-theme .verse,
#presentation-page.green-screen-theme .custom,
#presentation-page.green-screen-theme .lyrics,
#presentation-page.green-screen-theme .songTitle,
#presentation-page.green-screen-theme .message,
#presentation-page.green-screen-theme .date,
#presentation-page.green-screen-theme .title {
    color: #ffffff;
    text-shadow: 10px 10px 15px #090c0d;
}

/*HOFCC ANIMATION THEME*/
#presentation-page.hofcc-ani-theme {
    /* background-image: radial-gradient(#5b7c8a, #090c0d); */
    background: linear-gradient(33deg, #5b7c8a, #090c0d, #3f4243, #5b7c8a);
    background-size: 800% 800%;
    
    -webkit-animation: AnimationName 240s ease infinite;
    -moz-animation: AnimationName 240s ease infinite;
    animation: AnimationName 240s ease infinite;
}

#presentation-page.hofcc-ani-theme .verse {
    border-left-color: #5b7c8a;
}

#presentation-page.hofcc-ani-theme .reference {
    background: #5b7c8a;
    color: #ffffff !important;
}

#presentation-page.hofcc-ani-theme .reference,
#presentation-page.hofcc-ani-theme .verse,
#presentation-page.hofcc-ani-theme .custom,
#presentation-page.hofcc-ani-theme .lyrics,
#presentation-page.hofcc-ani-theme .songTitle,
#presentation-page.hofcc-ani-theme .message,
#presentation-page.hofcc-ani-theme .date,
#presentation-page.hofcc-ani-theme .title {
    color: #ffffff;
    text-shadow: 10px 10px 15px #090c0d;
}

.animated {
    animation-duration: 2s;
}

/* #presentation-page .custom {
    position: relative;
} */

#presentation-page .custom  {
    position: relative;
}

#presentation-page .custom span {
    display: none;
}

#presentation-page .custom h1+span{
    line-height: .8;
    font-family: 'NewhouseDTCond-ExtBold';
    font-size: 15rem;
    opacity: .1;
    position: fixed;
    bottom: -40px;
    left: 0;
    text-align: center;
    display: block;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scaleY(1.2);
}

#presentation-page .custom h1 {
    font-family: 'NewhouseDTCond-ExtBold';
    letter-spacing: 10px;
    /* opacity: .1; */
    line-height: 1;
    z-index: 3;
    position: relative;
}

#presentation-page .custom h2 {
    font-family: 'NewhouseDTCond-Light';
    letter-spacing: 3px;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 72%}
    50%{background-position:100% 29%}
    100%{background-position:0% 72%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 72%}
    50%{background-position:100% 29%}
    100%{background-position:0% 72%}
}
@keyframes AnimationName { 
    0%{background-position:0% 72%}
    50%{background-position:100% 29%}
    100%{background-position:0% 72%}
}