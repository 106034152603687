.block-title {
    background: #fff;
    border-bottom: 1px solid #ebedf2;
    padding: 16px 16px 6px 16px;
    text-transform: uppercase;
}

#remote-page {
    /* background-image: radial-gradient(#7793BD, #2d4963); */
}

#remote-page .fabButton {
    position: absolute;
    z-index: 1;
    top: -17px;
    left: auto;
    right: 15px;
    margin: 0 auto;
    background: #5b7c8a;
}

#remote-page .appBar {
    top: auto;
    bottom: 0;
}

.appBar-verse {
    left: 0;
    right: 0;
    display: block;
    text-align: center;
}

.appBar-Tools button {
    font-size: 1rem;
    text-transform: uppercase;
}

.fabButton-verse {
    position: absolute !important;
    z-index: 1;
    top: 17px;
    left: auto;
    right: 15px;
    margin: 0 auto; 
    background: #5b7c8a !important;
}

#remote-page .logo-div {
    /* text-align: center; */
    padding: 20px 25px 5px 25px;
}

#remote-page .logo-div img {
    width: 100px;
}

#remote-page .header-title-div {
    text-align: right;
    padding: 25px 25px 10px 25px;
}


#remote-page .header-section {
    border-bottom: 10px solid black;
}

.verse-search-div-remote {
    height: 100vh;
    /* overflow: hidden; */
}

/* #remote-page .verse-controls {
    background: #5b7c8a;
    box-shadow: 0 5px 10px rgba(0,0,0,.5);
} */

.verse-controls button {
    font-size: 1rem;
    color: #ffffff;
}

.passage-select {
    margin-top: 85px;
    max-height: 85vh;
    overflow-y: auto;
}

.select-option .selected-option {
    font-weight: bold;
}

.verse-select {
    padding: 0 24px;
}

.verse-select button {
    text-transform: initial;
    text-align: left;
    align-items: baseline;
    width: 100%;
    justify-content: left;
}

.verse-select button .reference {
    font-size: 1.25rem;
    font-weight: bold;
    margin-right: 15px;
}

.verse-select .active {
    background: rgba(91,124,138,.3)!important;
}

#remote-page .worship-slides-div {
    /* height: 100vh; */
    padding-right: 0;
    overflow-y: auto;
}

#remote-page .itemTitle {
    border-top: 1px solid #5b7c8a;
    border-bottom: 1px solid #5b7c8a;
    background: rgba(91,124,138,.7);
}

#remote-page .itemTitle span p,
#remote-page .itemTitle svg {
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
}

#remote-page .item {
    /* border-bottom: 1px solid rgba(0,0,0,.5); */
}
#remote-page .item.active {
    background: rgba(91,124,138,.3)!important;
}

.songsList-divider button {
    font-size: 1rem;
    font-weight: bold;
}

.slides-list-remote {
    overflow-y: hidden;
    margin-bottom: 65px !important;
}


.books-select {
    padding: 0 24px;
}
.verse-search-div-remote .books-select {
    margin: 0 !important;
}

.books-select button span {
    justify-content: left;
    text-align: left;
}