#assets-page {

}

#assets-page .add-fab {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    z-index: 999;
}

#assets-page .list {
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15) !important;
    width: 100%;
    position: relative;
    overflow-y: auto;
    height: 80vh;
    background: #ffffff;
    /* max-height: 300px; */
}

#assets-page .list .subheader {
    height: 100px;
    line-height: 100px;
    background: #ffffff;
    font-size: 50px;
}

#assets-page .list-section {
    background-color: inherit;
}

#assets-page .asset-details {
    background: #ffffff;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15) !important;  
    padding: 40px;
}
/* 
#assets-page .slides-field {
    height: 400px;
} */